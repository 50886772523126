<template>
  <div class="statis">
    <el-table :data="statisticsList" style="width: 100%" border>
      <el-table-column prop="tempId" label="ID" align="center" width="80"></el-table-column>
      <el-table-column prop="name" label="服务名称" align="center">
        <template slot="header">
          服务名称
        </template>
      </el-table-column>
      <!-- 今日总数-->
      <el-table-column prop="adminTodayCount" label="今日管理员" align="center">
        <template slot="header">
          今日管理员<noun-help content="今日管理员查询的次数"/>
        </template>
      </el-table-column>
      <el-table-column prop="merchantTodayCount" label="今日管商户" align="center">
        <template slot="header">
          今日管商户<noun-help content="今日管商户查询的次数"/>
        </template>
      </el-table-column>
      <el-table-column prop="totalToday" label="今日总数" align="center">
        <template slot="header">
          今日总数<noun-help content="今日查询的总次数"/>
        </template>
      </el-table-column>
      <!--昨日总数-->
      <el-table-column prop="adminYesterdayCount" label="昨日管理员" align="center">
        <template slot="header">
          昨日管理员<noun-help content="昨日管理员查询的次数"/>
        </template>
      </el-table-column>
      <el-table-column prop="merchantYesterdayCount" label="昨日商户" align="center">
        <template slot="header">
          昨日商户<noun-help content="昨日商户查询的次数"/>
        </template>
      </el-table-column>
      <el-table-column prop="totalYesterday" label="昨日总数" align="center">
        <template slot="header">
          昨日总数<noun-help content="昨日查询的总次数"/>
        </template>
      </el-table-column>
      <!--历史总数-->
      <el-table-column prop="adminCount" label="累计管理员" align="center">
        <template slot="header">
          累计管理员<noun-help content="管理员查询的次数"/>
        </template>
      </el-table-column>
      <el-table-column prop="merchantCount" label="累计商户" align="center">
        <template slot="header">
          累计商户<noun-help content="累计商户查询的次数"/>
        </template>
      </el-table-column>
      <el-table-column prop="total" label="累计总数" align="center">
        <template slot="header">
          累计总数<noun-help content="累计查询的总次数"/>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import nounHelp from '@/components/noun-help'
export default {
    components:{ nounHelp },
  props: {
    statisticsList: {
      type: Array,
      default: () => []
    }
  },
};
</script>
