import API from "@/api";

export default {
  third: params => {
    return API.requestGet({
      url: "/reportProps/statistics/third",
      params,
      auth: true
    });
  }
};
