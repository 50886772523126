<template>
  <div class="main-wrap">
    <!-- 搜索 -->
    <search-form @search="search"></search-form>

    <!-- 链接表 -->
    <statistics-table :statistics-list="statisticsList"></statistics-table>
  </div>
</template>

<script>
// components
import searchForm from './components/search-form';
import statisticsTable from './components/statistics-table';
// api
import service from './api';

export default {
  data() {
    return {
      statisticsList: []
    };
  },
  components: {
    searchForm,
    statisticsTable
  },
  methods: {
    init() {
      this.getList();
    },
    // 获取统计列表
    getList(params) {
      return service.third(params).then(res => {
        res.forEach((item, index) => {
          item.tempId = index + 1;
        });
        this.statisticsList = res
      });
    },
    // 搜索
    search(data) {
      let params = { ...data };
      return this.getList(params);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.user-box {
  width: 100%;

  .name {
    width: 220px;
  }

  .change {
    width: 100%;
  }

  .tool-box {
    overflow: hidden;
    text-align: left;
    padding: 10px 10px;
    border-bottom: 1px solid #eee;
  }
}
</style>
